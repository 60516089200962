import React, { useState, useEffect } from 'react';
import bridge from '@vkontakte/vk-bridge';
import { View, ScreenSpinner, AdaptivityProvider, AppRoot, ConfigProvider, SplitLayout, SplitCol } from '@vkontakte/vkui';
import '@vkontakte/vkui/dist/vkui.css';

import Home from './panels/Home';
import Persik from './panels/Persik';

const App = () => {
	const [scheme, setScheme] = useState('bright_light')
	const [activePanel, setActivePanel] = useState('home');
	const [fetchedUser, setUser] = useState(null);
	const [popout, setPopout] = useState(<ScreenSpinner size='large' />);


	useEffect(() => {

		console.log(1234);
		bridge.send('VKWebAppGetLaunchParams')
			.then((data) => {
				if (data.vk_app_id) {
					// Параметры запуска получены
					console.log(data)
				}
			})
			.catch((error) => {
				// Ошибка
				console.log(error);
			});

		bridge.subscribe(({ detail: { type, data }}) => {
			if (type === 'VKWebAppUpdateConfig') {
				setScheme(data.scheme)
			}
		});

		async function fetchData() {
			const user = await bridge.send('VKWebAppGetUserInfo');
			setUser(user);
			setPopout(null);
		}
		fetchData();
	}, []);

	const go = e => {
		//setActivePanel(e.currentTarget.dataset.to);
		bridge.send('VKWebAppShowStoryBox', {
				"background_type": "image",
				"url": "https://sun9-65.userapi.com/c850136/v850136098/1b77eb/0YK6suXkY24.jpg",
				"stickers": [
					{
						"sticker_type": "renderable",
						"sticker": {
							"content_type": "image",
							"url": "https://static.tildacdn.com/tild3333-3535-4333-b836-666163623138/Asset_2.svg",
							"clickable_zones": [
								{
									"action_type": "app",
									"action": {
										"app_id": 51535034,
										"app_context": "tes_id=7"
									}
								}
							],
							"can_delete": false
						}
					}
				]
			})
			.then((data) => {
				if (data.code_data) {
					// Редактор истории открыт
				}})
			.catch((error) => {
				// Ошибка
				console.log(error);
			});
	};

	const widget = e => {
		// bridge.send("VKWebAppGetCommunityToken", {
		// 	app_id: 51535034,
		// 	group_id: 218494748,
		// 	scope: 'app_widget'
		// })
		// .then((data) => {
		// 	if (data.access_token) {
		// 		console.log('token: ' + data.access_token)

				bridge.send('VKWebAppShowCommunityWidgetPreviewBox', {
						group_id: 218494748,
						type: 'text',
						code: `return {
							"title": "Цитата",
							"text": "Текст цитаты"
						};`})
					.then((data) => {
						if (data.result) {
							// Экран предпросмотра показан
						}
					})
					.catch((error) => {
						// Ошибка
						console.log(error);
					});
		// 	}
		// })
		// .catch((error) => {
		// 	// Ошибка
		// 	console.log(error);
		// });


	}

	return (
		<ConfigProvider scheme={scheme}>
			<AdaptivityProvider>
				<AppRoot>
					<SplitLayout popout={popout}>
						<SplitCol>
							<View activePanel={activePanel}>
								<Home id='home' fetchedUser={fetchedUser} go={go} widget={widget}/>
								<Persik id='persik' go={go} />
							</View>
						</SplitCol>
					</SplitLayout>
				</AppRoot>
			</AdaptivityProvider>
		</ConfigProvider>
	);
}

export default App;
